import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import pageStyles from "./styles.scss";

// Image Imports
import imrCommercial from "../images/IMR_Commercial.png";

// Video Imports
import sethVideo from "../videos/Seth_TOCartoonist.mp4";
import ecoSchoolVideo from "../videos/Eco_schools.mp4";
import parachuteVideo from "../videos/Parachute_Canada_final.mp4";
import trcaUrbanVideo from "../videos/TRCAUrban_Farm.mp4";
import imrVideo from "../videos/IMR_vimeo.mp4";
import pipgVideo from "../videos/PIPG_Annual_testimonials.mp4";
import digitalMainstreetVideo from "../videos/Digital_mainstreet.mp4";

const VIDEO_MODAL_ID = "video_modal_id";

const IndexPage = ({ data }) => {
  const [videoState, updateVideoState] = useState({
    videoSrc: null,
    videoWidth: null,
    videoHeight: null,
  });

  const openVideo = (newVideoState) => {
    updateVideoState(newVideoState);
  };

  const closeVideo = () => {
    updateVideoState({ videoSrc: null, videoWidth: null, videoHeight: null });
  };

  const openResume = () => {
    window.open("Zlatko_Cetinic_imrvideo.pdf");
  };

  useEffect(() => {
    const closeOnEscape = (e) => {
      if (e.which === 27) {
        closeVideo();
      }
    };
    window.addEventListener("keydown", closeOnEscape);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("keydown", closeOnEscape);
    };
  });

  return (
    <main>
      {videoState.videoSrc !== null && (
        <div
          onClick={() => videoState.videoSrc !== null && closeVideo()}
          id={VIDEO_MODAL_ID}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgb(46 46 46 / 90%)",
            position: "fixed",
            height: "100vh",
            width: "100vw",
            zIndex: 100,
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              position: "relative",
              zIndex: 300,
              pointerEvents: "none",
              height: "auto",
              width: "auto",
            }}
          >
            <video
              autoPlay
              style={{ pointerEvents: "auto", zIndex: 301 }}
              onLoadedData={(e) => {}}
              width={videoState.videoWidth}
              height={videoState.videoHeight}
              controls
            >
              <source src={videoState.videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
      <div className={videoState.videoSrc !== null ? "video-modal-active" : ""}>
        <title style={{ display: "hidden" }}>IMR Video</title>

        {/* TOP BANNER */}
        <div
          style={{
            height: "auto",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <StaticImage
              placeholder="blurred"
              src="../images/imr_video_top.png"
              alt="cityscape background"
            />
          </div>
          <p
            style={{
              fontFamily: "Montserrat",
              fontSize: "2vw",
              position: "absolute",
              color: "white",
            }}
            className="resp-title"
          >
            Providing affordable high quality video production and photography
          </p>
        </div>
        {/* END TOP BANNER */}

        {/* NAV */}
        <div className="imr-nav">
          <div className="imr-inner-nav">
            <a href="/"><div className="imr-nav-link">Video</div></a>
            <a href="/designportfolio"><div className="imr-nav-link">Design</div></a>
            <a href="/references"><div className="imr-nav-link">References</div></a>
          </div>
        </div>
        {/* END NAV */}

        <h1 style={{ display: "none" }}>IMR Video</h1>

      {/* CHANGEABLE PAGE */}
      <div style={{display:'flex', justifyContent:'center'}}>
        <div>
          <div className="video-row-container" style={{ display: "flex" }}>
          <div
            className="video-container video-container-left"
            onClick={() => openVideo({ videoSrc: parachuteVideo })}
          >
            <StaticImage
              className="video-container-img"
              placeholder="blurred"
              src="../images/Parachute_Canada.png"
              alt="Seth"
            />
          </div>
          <div
            className="video-container video-container-right"
            onClick={() => openVideo({ videoSrc: digitalMainstreetVideo })}
          >
            <StaticImage
              className="video-container-img"
              placeholder="blurred"
              src="../images/TO_EcoDev.png"
              alt="Toronto Eco Development Photo"
            />
          </div>
        </div>
        <div className="video-row-container" style={{ display: "flex" }}>
          <div
            className="video-container video-container-left"
            onClick={() => openVideo({ videoSrc: pipgVideo })}
          >
            <StaticImage
              className="video-container-img"
              placeholder="blurred"
              src="../images/Partners_ProjectGreen.png"
              alt="Partners in project green"
            />
          </div>
          <div
            className="video-container video-container-right"
            onClick={() => openVideo({ videoSrc: trcaUrbanVideo })}
          >
            <StaticImage
              className="video-container-img"
              placeholder="blurred"
              src="../images/TRCA_UrbanFarm.png"
              alt="Urban farming TRCA"
            />
          </div>
        </div>
        <div className="video-row-container" style={{ display: "flex" }}>
          <div
            className="video-container video-container-left"
            onClick={() => openVideo({ videoSrc: ecoSchoolVideo })}
          >
            <StaticImage
              className="video-container-img"
              placeholder="blurred"
              src="../images/Ont_EcoSchools.png"
              alt="Ontario eco-schools"
            />
          </div>
          <div
            className="video-container video-container-right"
            onClick={() => openVideo({ videoSrc: sethVideo })}
          >
            <StaticImage
              className="video-container-img"
              placeholder="blurred"
              src="../images/SETH.png"
              alt="Seth"
            />
          </div>
        </div>
        <div className="video-row-container" style={{ display: "flex" }}>
          <div
            className="video-container video-container-left"
            onClick={() => openVideo({ videoSrc: imrVideo })}
          >
            <StaticImage
              className="video-container-img"
              placeholder="blurred"
              src="../images/IMR_Commercial.png"
              alt="Images made real commercial"
            />
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="video-container-resume video-container-right"
            onClick={openResume}
          >
            <StaticImage
              className="video-container-img"
              placeholder="blurred"
              fit="cover"
              src="../images/Zlatko_Cetinic.png"
              alt="Zlatko Cetinic"
            />
          </div>
        </div>
        </div>
      </div>
      {/* END CHANGEABLE PAGE */}

      </div>
    </main>
  );
};

export const query = graphql`
  query HomePageQuery {
    allFile {
      edges {
        node {
          id
          previewWebP: videoWebP(maxWidth: 600, fps: 4, duration: 2) {
            path
          }
          previewGif: videoGif(maxWidth: 600, fps: 4, duration: 2) {
            path
          }
          videoScreenshots(timestamps: ["0", "1", "50%", "99%"]) {
            publicURL
          }
        }
      }
    }
  }
`;

export default IndexPage;
